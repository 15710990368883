<template>
    <v-container id="home" fill-height fluid>
        <v-row>
            <v-col>
                <h1 class="text-center">HOME</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
  name: "Home",
  data: () => ({
    links: [
      
    ]
  }),
  methods: {

  },
  created() {

  },
  computed: {
  }
};
</script>